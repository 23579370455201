export const isAlpha = true;

export type Server = {
    serverAdress: string;
    wsServerAdress: string;
};

export const LOCAL_SERVER_ADDRESS='http://localhost:5005';
export const LOCAL_WS_SERVER_ADDRESS='http://localhost:5005';

export const server: Server = {
    //@ts-ignore
    serverAdress: process.env.SERVER_ADDRESS ? `${process.env.SERVER_ADDRESS}` : LOCAL_SERVER_ADDRESS,
    //@ts-ignore
    wsServerAdress: process.env.WS_SERVER_ADDRESS ? `${process.env.WS_SERVER_ADDRESS}` : LOCAL_WS_SERVER_ADDRESS,
}

export const RTMT_WS_PING_INTERVAL = 1000, RTMT_WS_PING_INTERVAL_BUFFER_TIME = 2000;